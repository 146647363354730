import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AdminWebUserEntity } from '@core/entities/user/admin-web-user.entity';
import { ProxyRolesHelper } from '@core/helpers/proxy-roles.helper';
import { ProxyUserService } from '@core/services/proxy/proxy-user.service';
import { AuthStore } from '@core/stores/auth.store';
import { EmployerService } from '@modules/employer/services/employer.service';
import { IdRolesHelper } from '@modules/user-management/helpers/id-roles.helper';
import { IdUserService } from '@core/services/id/id-user.service';
import { MembershipService } from '@core/services/membership/membership.service';
import { StrikelogService } from '@modules/strikelog/services/strikelog.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { catchError, map } from 'rxjs/operators';
import { ModalService } from '@shared/components/modal/modal.service';
import { TextModalComponent } from '@shared/components/modal/content/text-modal/text-modal.component';
import { TextModalDataInterface } from '@shared/components/modal/content/text-modal/text-modal-data.interface';
import { MembershipUser } from '@modules/membership-cancellations/types/membership-user.interface';
import { FilesystemService } from '@core/services/filesystem/filesystem.service';
import { IdRoleInterface } from '@core/interfaces/id-role.interface';
import { PermissionStatus } from '@core/enums/permission-status.enum';
import { HttpContext, HttpErrorResponse } from '@angular/common/http';
import { MaintenanceHelper } from '@core/helpers/maintenance.helper';
import { SKIP_MAINTENANCE_INTERCEPTOR } from '@core/interceptors/maintenance.interceptor';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly #authStore = inject(AuthStore);
  readonly #employerService = inject(EmployerService);
  readonly #filesystemService = inject(FilesystemService);
  readonly #idUserService = inject(IdUserService);
  readonly #membershipService = inject(MembershipService);
  readonly #modalService = inject(ModalService);
  readonly #proxyUserService = inject(ProxyUserService);
  readonly #router = inject(Router);
  readonly #strikeLogService = inject(StrikelogService);
  readonly #waitingForAuth$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  waitingForAuth$ = this.#waitingForAuth$.asObservable();

  authCompleted(): void {
    this.#waitingForAuth$.next(false);
  }

  deleteStoredUser(): void {
    this.#authStore.deleteItem(AuthStore.KEY_UUID);
    this.#authStore.deleteItem(AuthStore.KEY_FIRST_NAME);
    this.#authStore.deleteItem(AuthStore.KEY_LAST_NAME);
    this.#authStore.deleteItem(AuthStore.KEY_REMEMBER_ME);
  }

  getSelf(): Observable<AdminWebUserEntity | undefined> {
    return this.#authStore.user$;
  }

  getStoredUuid(): string | undefined {
    return this.#authStore.readItem<string>(AuthStore.KEY_UUID);
  }

  getStoredFirstName(): string | undefined {
    return this.#authStore.readItem<string>(AuthStore.KEY_FIRST_NAME);
  }

  getStoredLastName(): string | undefined {
    return this.#authStore.readItem<string>(AuthStore.KEY_LAST_NAME);
  }

  getStoredRememberMe(): boolean {
    return !!this.#authStore.readItem<boolean>(AuthStore.KEY_REMEMBER_ME);
  }

  handlePermission(status: PermissionStatus): boolean {
    switch (status) {
      case PermissionStatus.ACCESS:
        return true;
      case PermissionStatus.MAINTENANCE:
        this.#router.navigate(['/dashboard']).then(() => {
          this.showAccessModal(
            'Planlagt vedlikehold',
            `<p>Denne modulen er under planlagt vedlikehold. </p><p>For avklaring og spørsmål kan du <a href="https://kontakt.fagforbundet.no/">kontakte brukerstøtte her.</a></p>`
          );
        });

        return false;
      case PermissionStatus.NO_ACCESS:
        this.showAccessModal(
          'Mangler tilgang',
          `<p>Du mangler tilgang til dette verktøyet. </p><p>For avklaring og spørsmål kan du <a href="https://kontakt.fagforbundet.no/">kontakte brukerstøtte her.</a></p>`
        );

        return false;
      case PermissionStatus.UNDER_DEVELOPMENT:
        this.showAccessModal(
          'Under utvikling',
          `<p>Denne modulen er under utvikling. </p><p>For avklaring og spørsmål kan du <a href="https://kontakt.fagforbundet.no/">kontakte brukerstøtte her.</a></p>`
        );

        return false;
      case PermissionStatus.UNRESOLVED:
      default:
        this.showAccessModal(
          'Feil oppstod',
          `<p>Vi fikk dessverre ikke sjekket om du har tilgang til denne modulen.</p><p>Prøv igjen, og om du fremdeles opplever problemer kan du <a href="https://kontakt.fagforbundet.no/" class="ff-link">kontakte brukerstøtte her.</a></p>`,
        );

        return false;
    }
  }

  /**
   * Role "ROLE_ORGANIZATIONAL_UNIT_ADMIN" in "Membership" means you have access
   */
  hasCountyManagementAccess(): Observable<PermissionStatus> {
    return this.#membershipService.getSelfCached(new HttpContext().set(SKIP_MAINTENANCE_INTERCEPTOR, true)).pipe(
      map((user: MembershipUser) => {
        return user.roles.some(
          (role) => role.name === 'ROLE_ORGANIZATIONAL_UNIT_ADMIN',
        ) ? PermissionStatus.ACCESS : PermissionStatus.NO_ACCESS;
      }),
      catchError((e: HttpErrorResponse) => {
        return MaintenanceHelper.isMaintenanceResponse(e) ? of(PermissionStatus.MAINTENANCE) : of(PermissionStatus.UNRESOLVED);
      }),
    );
  }

  /**
   *  TODO: Implement
   */
  hasDistributionAccess(): Observable<PermissionStatus> {
    return of(PermissionStatus.ACCESS);
  }

  /**
   * Role "ROLE_ADMIN" in "Employer" means you have access
   */
  hasEmployerManagementAccess(): Observable<PermissionStatus> {
    return this.#employerService.getSelfCached(new HttpContext().set(SKIP_MAINTENANCE_INTERCEPTOR, true)).pipe(
      map((response) => {
        return response.roles.some((role) => role.role === 'ROLE_ADMIN') ? PermissionStatus.ACCESS : PermissionStatus.NO_ACCESS;
      }),
      catchError((e: HttpErrorResponse) => {
        return MaintenanceHelper.isMaintenanceResponse(e) ? of(PermissionStatus.MAINTENANCE) : of(PermissionStatus.UNRESOLVED);
      }),
    );
  }

  /**
   * One or more roles in "Files" means you have access
   */
  hasFilesystemAccess(): Observable<PermissionStatus> {
    return this.#filesystemService.getSelfRoles(new HttpContext().set(SKIP_MAINTENANCE_INTERCEPTOR, true)).pipe(
      map((roles: IdRoleInterface[]) => {
        return roles?.length > 0 ? PermissionStatus.ACCESS : PermissionStatus.NO_ACCESS;
      }),
      catchError((e: HttpErrorResponse) => {
        return MaintenanceHelper.isMaintenanceResponse(e) ? of(PermissionStatus.MAINTENANCE) : of(PermissionStatus.UNRESOLVED);
      }),
    );
  }

  /**
   * Access to this endpoint means you have access
   */
  hasMembershipCancellationAccess(): Observable<PermissionStatus> {
    return this.#membershipService.getCancellations({ limit: 1 }, new HttpContext().set(SKIP_MAINTENANCE_INTERCEPTOR, true)).pipe(
      map(() => {
        return PermissionStatus.ACCESS;
      }),
      catchError((e: HttpErrorResponse) => {
        return MaintenanceHelper.isMaintenanceResponse(e) ? of(PermissionStatus.MAINTENANCE) : of(PermissionStatus.UNRESOLVED);
      }),
    );
  }

  /**
   *  TODO: Implement
   */
  hasScholarshipAccess(): Observable<PermissionStatus> {
    return of(PermissionStatus.UNDER_DEVELOPMENT);
  }

  /**
   * One or more roles in "StrikeLog" means you have access
   */
  hasStrikeLogAccess(): Observable<PermissionStatus> {
    return this.#strikeLogService.getSelf(new HttpContext().set(SKIP_MAINTENANCE_INTERCEPTOR, true)).pipe(
      map((self: {roles: any[]}) => {
        return self.roles.length > 0 ? PermissionStatus.ACCESS : PermissionStatus.NO_ACCESS;
      }),
      catchError((e: HttpErrorResponse) => {
        return MaintenanceHelper.isMaintenanceResponse(e) ? of(PermissionStatus.MAINTENANCE) : of(PermissionStatus.UNRESOLVED);
      }),
    );
  }

  /**
   * If user is "user manager" in "ID", this means they have access
   */
  hasUserManagementAccess(): Observable<PermissionStatus> {
    return this.#idUserService.getSelf(new HttpContext().set(SKIP_MAINTENANCE_INTERCEPTOR, true)).pipe(
      map((self) => {
        return IdRolesHelper.isUserManager(self) ? PermissionStatus.ACCESS : PermissionStatus.NO_ACCESS;
      }),
      catchError((e: HttpErrorResponse) => {
        return MaintenanceHelper.isMaintenanceResponse(e) ? of(PermissionStatus.MAINTENANCE) : of(PermissionStatus.UNRESOLVED);
      }),
    );
  }

  /**
   * If user has role "ROLE_SECTION_ADMIN" in "Membership", they have access
   */
  hasVocationalAccess(): Observable<PermissionStatus> {
    return this.#membershipService.getSelfCached(new HttpContext().set(SKIP_MAINTENANCE_INTERCEPTOR, true)).pipe(
      map((user: MembershipUser) => {
        return user.roles.some(
          (role) => role.name === 'ROLE_SECTION_ADMIN',
        ) ? PermissionStatus.ACCESS : PermissionStatus.NO_ACCESS;
      }),
      catchError((e: HttpErrorResponse) => {
        return MaintenanceHelper.isMaintenanceResponse(e) ? of(PermissionStatus.MAINTENANCE) : of(PermissionStatus.UNRESOLVED);
      }),
    );
  }

  /**
   * If user can manage forwarding emails (in "Proxy"), they have access
   */
  hasZendeskAccess(): Observable<PermissionStatus> {
    return this.#proxyUserService.getSelf(new HttpContext().set(SKIP_MAINTENANCE_INTERCEPTOR, true)).pipe(
      map((self) => {
        return ProxyRolesHelper.canManageForwardingEmails(self) ? PermissionStatus.ACCESS : PermissionStatus.NO_ACCESS;
      }),
      catchError((e: HttpErrorResponse) => {
        return MaintenanceHelper.isMaintenanceResponse(e) ? of(PermissionStatus.MAINTENANCE) : of(PermissionStatus.UNRESOLVED);
      }),
    );
  }

  navigateIfStoredRouteExists(): Observable<boolean> {
    const storedRoute = this.#authStore.readItem<string>(AuthStore.KEY_REDIRECT_ROUTE, true);
    const storedRouteStamp = this.#authStore.readItem<number>(AuthStore.KEY_REDIRECT_ROUTE_TIMESTAMP, true);
    const storedRouteExpired = storedRouteStamp == null || (new Date).getTime() > (storedRouteStamp + AuthStore.TTL_REDIRECT_ROUTE);

    if (storedRoute != null && !storedRouteExpired) {
      return fromPromise(this.#router.navigateByUrl(decodeURIComponent(storedRoute)));
    }

    return of(false);
  }

  /**
   * Saves the requested route to storage.
   */
  setStoredRedirectRoute(route: string): void {
    this.#authStore.storeItem<string>(AuthStore.KEY_REDIRECT_ROUTE, encodeURIComponent(route));
    this.#authStore.storeItem<number>(AuthStore.KEY_REDIRECT_ROUTE_TIMESTAMP, new Date().getTime());
  }

  /**
   *  Saves user name and uuid
   */
  setStoredUser(uuid?: string, firstName?: string, lastName?: string): void {
    if (uuid) {
      this.#authStore.storeItem<string>(AuthStore.KEY_UUID, uuid);
    }

    if (firstName) {
      this.#authStore.storeItem<string>(AuthStore.KEY_FIRST_NAME, firstName);
    }

    if (lastName) {
      this.#authStore.storeItem<string>(AuthStore.KEY_LAST_NAME, lastName);
    }
  }

  showAccessModal(title: string, bodyHtml: string): void {
    this.#modalService.openModal({
      component: TextModalComponent,
      title,
      bodyHtml,
    } satisfies TextModalDataInterface);
  }

  setRememberMe(rememberMe: boolean): void {
    this.#authStore.storeItem<boolean>(AuthStore.KEY_REMEMBER_ME, rememberMe);
  }
}
