import { HttpClient, HttpContext, HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from "@angular/core";
import { EnvironmentHelper } from "@core/helpers/environment.helper";
import { firstValueFrom, Observable, of } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { MembershipUser } from "@modules/membership-cancellations/types/membership-user.interface";
import { PatchRequestDto } from "@core/entities/patch.request-dto";
import { CancellationsSearchParams } from "@modules/membership-cancellations/types/CancellationSearchParams.interface";
import {
  MembershipCancellationEntity
} from "@modules/membership-cancellations/entities/membership-cancellation.entity";
import { deserialize } from "serializr";
import { CancellationTransition } from "@modules/membership-cancellations/entities/cancellation-states.entity";
import {
  CancellationPostStateRequestEntity
} from "@modules/membership-cancellations/entities/cancellation-post-state-request.entity";
import { NameSort, UnionSort } from "@modules/membership-cancellations/types/CancellationSorting.type";
import { EmployerEntity } from "@core/entities/employer/employer.entity";
import { WorkplaceEntity } from "@core/entities/workplace/workplace.entity";
import { fromPromise } from "rxjs/internal/observable/innerFrom";
import { CancellationUnionEntity } from "@modules/membership-cancellations/entities/cancellation-union.entity";
import { OrganizationalUnitApiParams } from "@modules/membership-cancellations/types/OrganizationalUnitApiParams.type";
import { OrganizationalUnit } from "@modules/membership-cancellations/types/OrganizationalUnit.interface";
import { RegionAccessApiParams } from "@modules/membership-cancellations/types/RegionAccessApiParams.type";
import { UserRegionAccess } from "@modules/membership-cancellations/types/UserRegionAccess.interface";
import { CancellationNote } from "@modules/membership-cancellations/types/CancellationNote.interface";
import {
  GetProfessionsResponse
} from "@core/models/requests/membership-api/get-professions/v1/get-professions-response.type";
import { membershipApiConfig } from "@config/apis/membership/membership-api.config";
import { MembershipApiEndpoints } from "@config/apis/membership/membership-api-endpoints";
import { FfNgxRequestService } from "@fagforbundet/ngx-components";
import { Profession } from "@core/models/membership/profession.type";

@Injectable({
  providedIn: "root"
})
export class MembershipService {
  readonly #httpClient = inject(HttpClient);
  readonly #requestService = inject(FfNgxRequestService);
  #self$!: Observable<MembershipUser>;

  getSelfCached(context?: HttpContext): Observable<MembershipUser> {
    if (!this.#self$) {
      this.#self$ = this.#httpClient
        .get<{ user: MembershipUser }>(
          EnvironmentHelper.fetchAPIBase("membership") + "/v1/users/self"
        , {context})
        .pipe(
          map((response) => response.user),
          shareReplay(1)
        );
    }

    return this.#self$;
  }

  patchOrganizationalUnit(organizationUnitUuid: string, patchRequest: PatchRequestDto): Observable<any> {
    return this.#httpClient.patch(EnvironmentHelper.fetchAPIBase("membership") + "/v1/organizational-units/" + organizationUnitUuid, patchRequest);
  }

  getCancellations(searchParams: CancellationsSearchParams, context?: HttpContext): Observable<{
    cancellations: MembershipCancellationEntity[];
    totalCancellations: number;
  }> {
    return this.#httpClient
      .get<{
        membershipCancellations: MembershipCancellationEntity[];
        count: string;
      }>(
        EnvironmentHelper.fetchAPIBase('membership') +
        `/v1/membership-cancellations`,
        {
          context,
          observe: 'response',
          params: { ...searchParams },
        }
      )
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return {
              cancellations: [],
              totalCancellations: 0,
            };
          } else {
            return {
              cancellations: deserialize(
                MembershipCancellationEntity,
                response.body?.membershipCancellations ?? []
              ),
              totalCancellations: Number(response.body?.count),
            };
          }
        })
      );
  }

  updateCancellationState(
    uuid: string,
    transition: CancellationTransition
  ): Observable<CancellationPostStateRequestEntity> {
    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase('membership') +
        `/v1/membership-cancellations/${uuid}/transition`,
        { transition },
        { observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<{ membershipCancellation: object }>) => {
          return deserialize(
            CancellationPostStateRequestEntity,
            response.body?.membershipCancellation
          );
        })
      );
  }

  refreshCancellationsFane2(): Observable<any> {
    return this.#httpClient.post(
      EnvironmentHelper.fetchAPIBase('membership') +
      '/v1/membership-cancellations/update-status',
      { observe: 'response' }
    );
  }

  getEmployers(searchParams?: {
    limit?: number;
    offset?: number;
    name?: string;
    sorting?: NameSort;
  }): Observable<EmployerEntity[]> {
    return this.#httpClient
      .get(
        EnvironmentHelper.fetchAPIBase('membership') +
        `/v1/membership-cancellations/employers`,
        {
          observe: 'response',
          params: searchParams,
        }
      )
      .pipe(
        map((response: HttpResponse<{ employers: object[] }>) => {
          if (response.status === 204) {
            return [];
          } else {
            return deserialize(EmployerEntity, response.body?.employers ?? []);
          }
        })
      );
  }

  getWorkplaces(searchParams?: {
    limit?: number;
    offset?: number;
    name?: string;
    sorting?: NameSort;
  }): Observable<WorkplaceEntity[]> {
    return this.#httpClient
      .get(
        EnvironmentHelper.fetchAPIBase('membership') +
        `/v1/membership-cancellations/workplaces`,
        {
          observe: 'response',
          params: searchParams,
        }
      )
      .pipe(
        map((response: HttpResponse<{ workplaces: object[] }>) => {
          if (response.status === 204) {
            return [];
          } else {
            return deserialize(
              WorkplaceEntity,
              response.body?.workplaces ?? []
            );
          }
        })
      );
  }

  getAllEmployers(sorting?: NameSort): Observable<EmployerEntity[]> {
    return fromPromise(this.#fetchAllEmployers(sorting));
  }

  getAllUnions(sorting?: NameSort): Observable<CancellationUnionEntity[]> {
    return fromPromise(this.#fetchAllUnions(sorting));
  }

  getAllWorkplaces(sorting?: NameSort): Observable<WorkplaceEntity[]> {
    return fromPromise(this.#fetchAllWorkplaces(sorting));
  }

  getUnions(searchParams?: {
    limit?: number;
    offset?: number;
    name?: string;
    sorting?: UnionSort;
  }): Observable<CancellationUnionEntity[]> {
    return this.#httpClient
      .get(
        EnvironmentHelper.fetchAPIBase('membership') +
        `/v1/membership-cancellations/unions`,
        {
          observe: 'response',
          params: searchParams,
        }
      )
      .pipe(
        map((response: HttpResponse<{ unions: object[] }>) => {
          if (response.status === 204) {
            return [];
          } else {
            return deserialize(
              CancellationUnionEntity,
              response.body?.unions ?? []
            );
          }
        })
      );
  }

  getOrganizationalUnits(
    searchParams?: OrganizationalUnitApiParams
  ): Observable<OrganizationalUnit[]> {
    let params = new HttpParams({ fromObject: searchParams });

    if (searchParams && searchParams.types) {
      params = params.set('types', searchParams.types.join());
    }

    return this.#httpClient
      .get(
        EnvironmentHelper.fetchAPIBase('membership') +
        `/v1/organizational-units`,
        {
          observe: 'response',
          params,
        }
      )
      .pipe(
        map(
          (
            response: HttpResponse<{
              organizationalUnits: OrganizationalUnit[];
            }>
          ) => {
            if (response.status === 204) {
              return [];
            } else {
              return response.body?.organizationalUnits ?? [];
            }
          }
        )
      );
  }

  getUserRegionAccesses(
    searchParams?: RegionAccessApiParams
  ): Observable<UserRegionAccess[]> {
    return this.#httpClient
      .get(
        EnvironmentHelper.fetchAPIBase('membership') +
        `/v1/membership-cancellation-user-region-accesses`,
        {
          observe: 'response',
          params: searchParams,
        }
      )
      .pipe(
        map(
          (
            response: HttpResponse<{ userRegionAccesses: UserRegionAccess[] }>
          ) => {
            if (response.status === 204) {
              return [];
            } else {
              return response.body?.userRegionAccesses ?? [];
            }
          }
        )
      );
  }

  postUserRegionAccess(
    idProviderUuid: string,
    regionUuid: string
  ): Observable<UserRegionAccess | undefined> {
    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase('membership') +
        `/v1/membership-cancellation-user-region-accesses`,
        { idProviderUuid, regionUuid },
        { observe: 'response' }
      )
      .pipe(
        map(
          (response: HttpResponse<{ userRegionAccess: UserRegionAccess }>) => {
            return response.body?.userRegionAccess;
          }
        )
      );
  }

  revokeUserRegionAccess(accessUuid: string): Observable<UserRegionAccess> {
    return this.#httpClient
      .post<{ userRegionAccess: UserRegionAccess }>(
        EnvironmentHelper.fetchAPIBase('membership') +
        `/v1/membership-cancellation-user-region-accesses/${accessUuid}/revoke`,
        {}
      )
      .pipe(
        map((response) => {
          return response.userRegionAccess;
        })
      );
  }

  getCancellationNotes(
    cancellationUuid: string,
    params?: {
      limit?: number;
      offset?: number;
    }
  ) {
    return this.#httpClient
      .get<{ notes?: CancellationNote[] }>(
        EnvironmentHelper.fetchAPIBase('membership') +
        `/v1/membership-cancellations/${cancellationUuid}/notes`,
        { params }
      )
      .pipe(
        map((response) => {
          return response?.notes ?? [];
        })
      );
  }

  postCancellationNote(cancellationUuid: string, text: string) {
    return this.#httpClient
      .post<{ note: CancellationNote }>(
        EnvironmentHelper.fetchAPIBase('membership') +
        `/v1/membership-cancellations/${cancellationUuid}/notes`,
        { note: { text } }
      )
      .pipe(
        map((response) => {
          return response.note;
        })
      );
  }

  async #fetchAllEmployers(sorting?: NameSort): Promise<EmployerEntity[]> {
    const params: {offset: number, sorting?:NameSort} = {
      offset: 0
    };

    if (sorting) {
      params.sorting = sorting;
    }

    const finalResult = [];
    let previousResult = [];

    do {
      previousResult = await firstValueFrom(this.getEmployers(params));
      finalResult.push(...previousResult);
      params.offset += 1000;
    } while (previousResult.length > 0);

    return firstValueFrom(of(finalResult));
  }

  async #fetchAllUnions(sorting?: NameSort): Promise<CancellationUnionEntity[]> {
    const params: {offset: number, sorting?: NameSort} = {
      offset: 0
    };

    if (sorting) {
      params.sorting = sorting;
    }

    const finalResult = [];
    let previousResult = [];

    do {
      previousResult = await firstValueFrom(this.getUnions(params));
      finalResult.push(...previousResult);
      params.offset += 1000;
    } while (previousResult.length > 0);

    return firstValueFrom(of(finalResult));
  }

  async #fetchAllWorkplaces(sorting?: NameSort): Promise<WorkplaceEntity[]> {
    const params: {offset: number, sorting?: NameSort} = {
      offset: 0
    };

    if (sorting) {
      params.sorting = sorting;
    }

    const finalResult = [];
    let previousResult = [];

    do {
      previousResult = await firstValueFrom(this.getWorkplaces(params));
      finalResult.push(...previousResult);
      params.offset += 1000;
    } while (previousResult.length > 0);

    return firstValueFrom(of(finalResult));
  }

  getProfessions(shouldCache: boolean): Observable<Profession[]> {
    return this.#requestService
      .get<GetProfessionsResponse>(
        {
          api: membershipApiConfig,
          endpoint: MembershipApiEndpoints.GET_PROFESSIONS,
        },
        { shouldCache },
      )
      .pipe(
        map((response) => {
          return response.responseBody.professions.map((profession) => {
            return {
              name: profession.name,
              uuid: profession.uuid,
              code: profession.code,
              fane2Id: profession.fane2Id,
            } satisfies Profession
          });
        }),
      );
  }
}
