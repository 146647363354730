import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from "@angular/core";
import { ProxyUserEntity } from '@core/entities/proxy/proxy-user.entity';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { ProxyEmployee } from '@core/entities/proxy/proxy-employee.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deserialize } from 'serializr';

@Injectable({
  providedIn: 'root',
})
export class ProxyUserService {
  readonly #httpClient = inject(HttpClient);

  getSelf(context?: HttpContext): Observable<ProxyUserEntity> {
    return this.#httpClient
      .get(EnvironmentHelper.fetchAPIBase('proxy') + '/v1/users/self', {
        context,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<{ user: object }>) => {
          return deserialize(ProxyUserEntity, response.body!.user);
        })
      );
  }

  getEmployee(idPortalUuid: string): Observable<ProxyEmployee> {
    return this.#httpClient
      .get<{ employee: ProxyEmployee }>(
        EnvironmentHelper.fetchAPIBase('proxy') +
          `/v1/employees/by-id-portal-uuid/${idPortalUuid}`
      )
      .pipe(
        map((response) => {
          return response.employee;
        })
      );
  }

  postEmployee(
    idPortalUuid: string,
    employeeNumber: string
  ): Observable<ProxyEmployee> {
    return this.#httpClient
      .post<{
        employee: ProxyEmployee;
      }>(EnvironmentHelper.fetchAPIBase('proxy') + '/v1/employees', {
        employee: { employeeNumber, idPortalUuid },
      })
      .pipe(map((response) => response.employee));
  }

  patchEmployeeNumber(
    uuid: string,
    employeeNumber: string
  ): Observable<ProxyEmployee> {
    return this.#httpClient
      .patch<{ employee: ProxyEmployee }>(
        EnvironmentHelper.fetchAPIBase('proxy') + `/v1/employees/${uuid}`,
        { attribute: 'employeeNumber', value: employeeNumber }
      )
      .pipe(map((response) => response.employee));
  }

  deleteEmployee(uuid: string): Observable<ProxyEmployee> {
    return this.#httpClient
      .delete<{ employee: ProxyEmployee }>(
        EnvironmentHelper.fetchAPIBase('proxy') + `/v1/employees/${uuid}`
      )
      .pipe(map((response) => response.employee));
  }
}
